import React from 'react';
import styled from 'styled-components';

import TextDivider from './UI/TextDivider';

const BenefitsStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 51px 0 60px 0;
    

    .benefit__item_list {
        list-style: none
    }

    #benefit__id_me{
        color: ${props => props.theme.colors.primaryYellow};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        margin-top: 62px;
    }

        .one9-benefits__title {
            margin: 0 20px;

            @media screen and (min-width: 1024px) {
                max-width: 760px;
            }
        }

        .benefit__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            margin-top: 38px;

            &:first-of-type {
                margin-top: 16px;
            }
        }

        .benefit__title {
            text-align: center;
            width: 83%;
            height: 60px;
            margin-bottom: 16px;
            color: ${props => props.theme.colors.primaryYellow};

            sup {
                font-size: 10px;
            }
        }

        .benefit {
            align-self: flex-start;
            box-sizing: border-box;
            width: 90%;
            
            .emphasize-body {
                font-weight: 600;
            }

            @media screen and (min-width: 1024px){
                width: 98%;

                .benefit__title {
                    height: 40px;
                }
            }

            
        }

        .benefit__item {
            display: flex;
            margin-bottom: 15px;
            line-height: 20px;
            list-style: none;

            &:last-child {
                margin-bottom: 0px;
            }

            sup {
                font-size: 10px;
            }
        }

        .benefit__item:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            margin: 5px 20px 0 20px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.primaryGray};
        }

        @media screen and (min-width: 1024px) {
            .benefits {
                display: flex;
                .benefit__container {
                    margin-top: 0;
                }
            }
        }
    }
`;

const Benefits = () => (
    <BenefitsStyled className="one9-benefits">
        <h3 className="one9-benefits__title">
            Big-fleet benefits for small fleets
        </h3>
        <TextDivider color="yellow" />
        <section className="benefits">
            <section className="benefit__container">
                <h4 className="benefit__title">
                    Convenient credit solutions with Axle Fuel Card&reg;
                </h4>

                <ul className="benefit">
                    <li className="benefit__item">
                        <p><span className="emphasize-body">24-hour approval</span>
                            <ul>
                                <li className="benefit__item_list">Apply today. Fuel tomorrow.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p><span className="emphasize-body">Enhanced network savings</span>
                            <ul>
                                <li className="benefit__item_list">Zero hidden fees. No management, transaction, or annual fees. Period.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Peace-of-mind security</span>
                            <ul>
                                <li className="benefit__item_list">Manage locations, set card limits, and easily monitor activity.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Payment processing made easy</span>
                            <ul>
                                <li className="benefit__item_list">Preferred payment terms with fewer fees and more flexibility.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Personalized service</span>
                            <ul>
                                <li className="benefit__item_list">Quick approvals and a dedicated account representative.</li>
                            </ul>
                        </p>
                    </li>
                </ul>
            </section>
            <section className="benefit__container">
                <h4 className="benefit__title">Earn more rewards at more pumps</h4>
                <ul className="benefit">
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Superior rewards</span>
                            <ul>
                                <li className="benefit__item_list">Earn and redeem loyalty points using myRewards and the myRewards Plus app at Pilot, Flying J, or One9 locations.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Earn more points faster</span>
                            <ul>
                                <li className="benefit__item_list">Earn up to 4 points per gallon with myRewards Plus when you activate PushForPoints&trade;. That’s 4 points per gallon after just 6 diesel fills!</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Earn Extra with Axle</span>
                            <ul>
                                <li className="benefit__item_list">Axle Fuel Card® users earn one extra bonus point per gallon.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Save more money and time with myRewards Plus</span>
                            <ul>
                                <li className="benefit__item_list">Get access to great features like mobile fueling, exclusive in-store offers, sweepstakes, challenges and more!</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">Free Showers</span>
                            <ul>
                                <li className="benefit__item_list">Earn free shower credits with every 50+ gallon purchase of commercial diesel.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p>
                            <span className="emphasize-body">10% Military discount with <a href='http://id.me/' id="benefit__id_me" target="_blank" rel="noreferrer noopener">ID.me</a>!</span>
                            <ul>
                                <li className="benefit__item_list">We appreciate our veterans and active military.</li>
                            </ul>
                        </p>
                    </li>
                </ul>
            </section>
            <section className="benefit__container">
                <h4 className="benefit__title">Expand your fuel network</h4>
                <ul className="benefit">
                    <li className="benefit__item">
                        <p><span className="emphasize-body">More locations, more savings, more rewards</span>
                            <ul>
                                <li className="benefit__item_list">One9 opens up more fueling locations in more markets across a variety of travel center brands.</li>
                            </ul>
                        </p>
                    </li>
                    <li className="benefit__item">
                        <p><span className="emphasize-body">Find locations</span>
                            <ul>
                                <li className="benefit__item_list">Explore the <span className="emphasize-body">Where to Fuel</span> block below to see all the locations you can stop, refresh, refuel, and get rewarded!</li>
                            </ul>
                        </p>
                    </li>
                </ul>
            </section>
        </section>
    </BenefitsStyled>
);

export default Benefits;
