import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from './Button';
import TextDivider from './UI/TextDivider';

const HomePageHeroStyled = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 852px;
    background: url(${props => props.bgImage}) no-repeat;
    background-position: 90% 0%;
    background-repeat: no-repeat;
    background-size: 758px 505px;
    background-color: ${props => props.theme.colors.primaryGray};

    .hero__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1160px;

        .headline {
            width: 80%;
            margin-top: 107px;
            color: #fff;
            text-align: center;
        }

        .hero__copy {
            margin: 0 21px;
            color: #fff;
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: center;
        }

        .button {
            margin: 21px 0 66px 0;
        }
    }

    @media screen and (min-width: 768px) {
        background-position: 100% 0%;

        .hero__content {
            margin-top: 70px;

            .headline {
                margin-top: 103px;
                width: 68%;
                font-size: ${props => props.theme.fontSizes.h1};
            }

            .hero__copy {
                width: 66%;
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        background-size: 1668px 1126px;
        background-position: right top;
        height: inherit;

        .hero__content {
            align-items: flex-start;
            width: 100%;
            height: 1051px;
            padding: 0 58px;
            box-sizing: border-box;

            .headline {
                margin-top: 243px;
                max-width: 700px;
                text-align: left;
            }

            .hero__copy {
                margin: 0;
                max-width: 540px;
                font-size: 1.125rem;
                line-height: 1.75rem;
                text-align: left;
            }

            .button {
                margin-bottom: 206px;
            }
        }
    }
`;

const HomePageHero = ({ bgImage, cta, heroClicked, subtitle, title }) => (
    <HomePageHeroStyled bgImage={bgImage} className="hero">
        <div className="hero__content">
            <h1 className="headline">{title}</h1>
            <TextDivider color="yellow" />
            <p className="hero__copy">{subtitle}</p>
            {cta ? (
                <Button
                    clicked={cta.isPageAnchor ? heroClicked : null}
                    color={cta.color}
                    id={cta.id}
                    isExternal={cta.isExternal ? cta.isExternal : false}
                    isPageAnchor={true}
                    url={!cta.isPageAnchor ? cta.url : null}
                    text={cta.text}
                />
            ) : null}
        </div>
    </HomePageHeroStyled>
);

HomePageHero.propTypes = {
    bgImage: PropTypes.string.isRequired,
    cta: PropTypes.shape({
        color: PropTypes.string,
        isExternal: PropTypes.bool,
        isPageAnchor: PropTypes.bool,
        text: PropTypes.string,
        url: PropTypes.string,
    }),
    heroClicked: PropTypes.func,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default HomePageHero;
