import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import scrollToElement from 'scroll-to-element';
import { getAuthenticated, getContentFromSquidex } from '../actions/squidex';

import IndexStyled from '../components/page-styles/index-styles';

import Benefits from '../components/Benefits';
import Button from '../components/Button';
import Card from '../components/Card/Card';
import HomePageHero from '../components/HomePageHero';
import Layout from '../components/Layout';
import LocationMap from '../components/LocationMap';
import SEO from '../components/SEO';
import Disclaimers from '../components/Disclaimers';
import RecentArticles from '../components/RecentArticles';

import HeroBG from '../images/hero-bg-image.jpg';
import HowToApplyImage from '../images/how-to-apply-trucker.jpg';
import WhatIsOne9Image from '../images/what-is-one9.jpg';

class IndexPage extends Component {
    state = {
        locationsPDF: '',
    };

    componentDidMount() {
        let promise = getAuthenticated(
            process.env.SQUIDEX_AUTH_STRING,
            process.env.SQUIDEX_CLIENT_ID,
            process.env.SQUIDEX_CLIENT_SECRET,
            process.env.SQUIDEX_HOST_URL
        );

        promise.then(token => {
            let promise = getContentFromSquidex(
                token,
                process.env.SQUIDEX_HOST_URL,
                'locations-pdf'
            );

            promise.then(response =>
                this.setLocationState(
                    `${process.env.SQUIDEX_ASSETS_PATH}${response.data.items[0].data.locations.iv[0]}`
                )
            );
        });
    }

    setLocationState(locationURL) {
        this.setState({
            locationsPDF: locationURL,
        });
    }

    buttonClicked = eventName => {
        window.dataLayer.push({
            event: eventName,
            pagePath: '/',
            pageTitle: 'Home',
        });
    };

    heroButtonClicked = (event, anchor) => {
        event.preventDefault();
        scrollToElement('#' + anchor, {
            offset: -142,
            duration: 1000,
        });
        this.buttonClicked('Learn More Clicked');
    };

    render() {
        const { size } = this.props;
        let whatIsOne9 = {
            bgColor: 'yellow',
            centerAlign: false,
            dividerColor: 'gray',
            hasBorderRadius: false,
            hasButton: false,
            hasImage: true,
            hasTab: false,
            image: {
                source: WhatIsOne9Image,
                alignment: 'left',
            },
            id: 'what-is-one9',
            title: 'The Power of One9',
            titleColor: 'gray',
        };

        let whereToFuel = {
            bgColor: 'white',
            centerAlign: false,
            dividerColor: 'yellow',
            hasBorderRadius: true,
            hasButton: false,
            hasImage: false,
            hasTab: false,
            id: 'where-to-fuel',
            title: 'More places to fuel',
            titleColor: 'gray',
        };

        // let howToPay = {
        //     bgColor: 'white',
        //     centerAlign: true,
        //     dividerColor: 'yellow',
        //     hasBorderRadius: true,
        //     hasButton: false,
        //     hasImage: false,
        //     hasShadow: false,
        //     hasTab: false,
        //     id: 'how-to-pay',
        //     title: 'How To Pay',
        //     titleColor: 'gray',
        // };

        let applyForNetwork = {
            bgColor: 'yellow',
            centerAlign: false,
            button: {
                color: 'gray',
                isExternal: false,
                text: 'Apply now',
                url: '/benefits',
            },
            dividerColor: 'gray',
            hasBorderRadius: false,
            hasButton: true,
            hasImage: true,
            hasTab: false,
            id: 'apply-for-network',
            image: {
                source: HowToApplyImage,
                alignment: 'right',
            },
            title: 'Join the One9 network',
            titleColor: 'gray',
        };
        return (
            <Layout size={size}>
                <IndexStyled>
                    <SEO title="Home" />
                    <HomePageHero
                        bgImage={HeroBG}
                        cta={{
                            color: 'yellow',
                            id: 'learn-more',
                            isExternal: false,
                            isPageAnchor: true,
                            text: 'Learn More',
                            url: '',
                        }}
                        heroClicked={ev =>
                            this.heroButtonClicked(ev, 'what-is-one9')
                        }
                        title="The One9 Fuel Network"
                        subtitle="Helping small fleets pull ahead"
                    />
                    {/* <Announcement /> */}
                    <Card {...whatIsOne9} isMobile={!size.width > 768}>
                        Fuel your business with One9. Our fuel network gives personalized
                        credit solutions, enhanced loyalty options, and more locations
                        to help you fuel your business.
                        <br />
                        <br />
                        One9 can help your business grow. A dedicated account representative
                        can help you manage your fueling needs so you can focus on driving
                        your business further than ever. Unleash the potential of your fleet with One9!
                        <Button
                            buttonSidePadding="39px"
                            color="gray"
                            isExternal={true}
                            isPageAnchor={true}
                            url="tel:865-292-6639"
                            text={
                                size.width < 1024
                                    ? 'Call 865-292-One9'
                                    : 'Call 865-292-One9(6639)'
                            }
                        />
                    </Card>
                    <Benefits />
                    <LocationMap>
                        {this.state.locationsPDF !== '' ? (
                            <Card
                                {...whereToFuel}
                                isMobile={!size.width > 768}
                                tabText={
                                    this.props.isContentHidden
                                        ? 'View Text'
                                        : 'Hide Text'
                                }
                            >
                                One9 opens up your fueling network to more travel centers and locations
                                across the United States, and the network is always growing.
                                Check out the full list of locations{' '}
                                <a
                                    href={
                                        this.state.locationsPDF
                                            ? this.state.locationsPDF
                                            : '#'
                                    }
                                    className="card__info__cta"
                                    onClick={() =>
                                        this.buttonClicked(
                                            'Downloaded Location PDF'
                                        )
                                    }
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    here
                                </a>
                                .<br />
                                <br /> Participating locations also have One9 Fuel Network indicated 
                                    on their diesel pumps and canopies! 
                            </Card>
                        ) : null}
                    </LocationMap>
                    <Card {...applyForNetwork}>
                        Unlock a larger fueling network, more flexible credit options, 
                        and peace of mind by joining One9! <br />
                        <br />
                        <span className="emphasize">
                            CALL&nbsp;
                            <a
                                href="tel:865-292-6639"
                                onClick={() =>
                                    this.buttonClicked('Phone Number Clicked')
                                }
                            >
                                865-292-ONE9(6639)
                            </a>
                        </span>
                    </Card>
                    <RecentArticles />
                    <Disclaimers />
                </IndexStyled>
            </Layout>
        );
    }
}

export default withSize()(IndexPage);
