import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import styled from 'styled-components';
import ArticleImage from '../images/article-image.png';
import TextDivider from './UI/TextDivider';
import useGlobal from '../store';

const RecentArticlesStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .recent-articles-title {
        margin: 60px 20px 0 20px;
        font: 500 1.5rem/1.625rem ${props => props.theme.fonts[1]};
        color: ${props => props.theme.colors.secondaryGrey};

        @media screen and (min-width: 1024px) {
            max-width: 760px;
        }
    }

    .recent-articles-container {
        margin-top: 20px;
    }

    .spotlight-image {
        height: 461px;
        background: url(${ArticleImage}) no-repeat;
        background-size: cover;
    }

    .spotlight-article-card {
        display: flex;
        flex-direction: column;
        max-height: 275px;
        min-height: 200px;
        margin: -100px auto 40px auto;
        width: 90%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.14);
        background-color: white;
        border-radius: 5px;
        padding-bottom: 24px;
    }

    .spotlight-article-header {
        display: flex;
        flex-direction: row;
        font: 600 0.875rem/1.33rem ${props => props.theme.fonts[0]};
        margin: 35px auto 25px 38px;
        color: ${props => props.theme.colors.secondaryGrey};
    }

    .spotlight-article-content-type {
        text-transform: uppercase;
        margin-right: 5px;
    }

    .spotlight-article-date {
        font-weight: 300;
        margin-left: 5px;
    }

    a {
        color: ${props => props.theme.colors.secondaryGrey} !important;
    }

    a:hover {
        color: ${props => props.theme.colors.primaryYellow} !important;
    }

    .spotlight-article-title {
        color: ${props => props.theme.colors.secondaryGrey};
        font: 500 1.25rem/1.75rem ${props => props.theme.fonts[1]};
        margin: 0px 38px 0px 38px;
        text-transform: uppercase;
    }

    .spotlight-article-title:hover {
        color: ${props => props.theme.colors.primaryYellow};
        text-decoration: underline;
    }

    .latest-articles-header {
        font: 500 1.5rem/1.65rem ${props => props.theme.fonts[1]};
        margin: 0px auto 20px auto;
        width: 90%;
    }

    .article-container {
        margin: 0px auto 40px auto;
        border-bottom: 1px solid #b3b3b3;
        width: 90%;
    }

    .final-article {
        border-bottom: 0 !important;
    }

    .article-header {
        display: flex;
        flex-direction: row;
        font: 600 0.875rem/1.33rem ${props => props.theme.fonts[0]};
        margin: 45px auto 25px 0px;
        color: ${props => props.theme.colors.secondaryGrey};
    }

    .article-content-type {
        text-transform: uppercase;
        margin-right: 5px;
    }

    .article-date {
        font-weight: 300;
        margin-left: 5px;
    }

    .article-title {
        color: black;
        font: 500 1.125rem/1.625rem ${props => props.theme.fonts[1]};
        margin: 0px 0px 45px 0px;
        text-transform: uppercase;
        color: ${props => props.theme.colors.secondaryGrey};
    }

    .article-title:hover {
        color: ${props => props.theme.colors.primaryYellow};
        text-decoration: underline;
    }

    .see-all-link-container {
        margin: 0px auto 40px auto;
        width: 90%;
    }

    .see-all-link {
        font: 400 1rem/1.5rem ${props => props.theme.fonts[1]};
        color: ${props => props.theme.colors.primaryYellow} !important;
    }

    .see-all-arrow {
        border: solid ${props => props.theme.colors.primaryYellow};
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        margin-left: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    @media screen and (min-width: 1024px) {
        .recent-articles-container {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        .recent-articles-title {
            font: 500 2.5rem/3rem ${props => props.theme.fonts[1]};
        }

        .latest-articles-container {
            width: 40%;
            margin-right: 40px;
        }

        .spotlight-image {
            width: 80%;
            height: 442px;
            min-height: initial;
            background-position: left;
        }

        .spotlight-article-card {
            margin-left: 240px;
            width: 65%;
        }

        .spotlight-article-header {
            margin-left: 50px;
        }

        .spotlight-article-title {
            color: black;
            font: 500 1.5rem/2rem ${props => props.theme.fonts[1]};
            margin: 0 50px;
        }

        .spotlight-article-container {
            width: 60%;
        }
    }
`;

const RecentArticles = props => {
    const [spotlightArticle, setSpotlightArticle] = useState();
    const [loaded, setLoaded] = useState(false);
    const [articles, setArticles] = useState();
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        async function getRecentArticles() {
            await globalActions.services.getRecentBlogs();
            setLoaded(true);
        }
        if (!loaded) {
            getRecentArticles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    if (
        articles == null &&
        globalState.recentBlogs != null &&
        globalState.recentBlogs.length >= 2 &&
        globalState.recentBlogs.length <= 4 &&
        loaded
    ) {
        setSpotlightArticle(globalState.recentBlogs[0]);
        setArticles(globalState.recentBlogs.slice(1, 4));
    }

    if (articles == null || spotlightArticle == null) {
        return <RecentArticlesStyled></RecentArticlesStyled>;
    } else {
        return (
            <RecentArticlesStyled>
                <h3 className="recent-articles-title">Fleet Insider By One9</h3>
                <TextDivider color="yellow" />
                <div className="recent-articles-container">
                    <div className="spotlight-article-container">
                        <div className="spotlight-image" />
                        <div className="spotlight-article-card">
                            <div className="spotlight-article-header">
                                <div className="spotlight-article-content-type">
                                    {spotlightArticle.contentTypeLabel}
                                </div>
                                |
                                <div className="spotlight-article-date">
                                    {spotlightArticle.publicationDate}
                                </div>
                            </div>
                            <div className="spotlight-article-title">
                                <a href={'/blog/' + spotlightArticle.slug}>
                                    {spotlightArticle.title}
                                </a>
                                <TextDivider />
                            </div>
                        </div>
                    </div>
                    <div className="latest-articles-container">
                        <div className="latest-articles-header">OUR LATEST</div>

                        {articles.map(
                            (article, articleIndex) => (
                                <div
                                    className={
                                        articleIndex === 2
                                            ? 'article-container final-article'
                                            : 'article-container'
                                    }
                                    key={articleIndex}
                                >
                                    <div className="article-header">
                                        <div className="article-content-type">
                                            {article.contentTypeLabel}
                                        </div>
                                        |
                                        <div className="article-date">
                                            {article.publicationDate}
                                        </div>
                                    </div>
                                    <div className="article-title">
                                        <a href={'/blog/' + article.slug}>
                                            {article.title}
                                        </a>
                                    </div>
                                </div>
                            ),
                            this
                        )}

                        <div className="see-all-link-container">
                            <a href="/blog" className="see-all-link">
                                SEE ALL <i className="see-all-arrow"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </RecentArticlesStyled>
        );
    }
};

export default withSize()(RecentArticles);
