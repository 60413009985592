import React from 'react';
import styled from 'styled-components';

const DisclaimersStyled = styled.section`
    & > div {
        width: calc(100% - 40px);
        margin: 3em auto;
        font-size: 0.75rem;
        line-height: 1.375rem;

        @media screen and (min-width: 768px) {
            width: calc(100% - 394px);
            font-size: 0.625rem;
        }
    }
`;

const Disclaimers = () => {
    return (
        <DisclaimersStyled>
            <div className="one9-terms_holder">
                <p className="one9-terms__copy">
                    Subject to credit approval. Terms and conditions apply.
                    The Axle Fuel Card® is accepted at Pilot and Flying J Travel Centers,
                    the One9 Fuel Network, and at Southern Tire Mart at Pilot Flying J
                    Truck Care Service Centers. Accounts are available for commercial
                    use only including diesel and mixed fuel fleets, but gas only
                    fleets are not eligible for approval.
                </p>
                <br />
                <p className="one9-terms__copy">
                    You will receive one bonus myRewards loyalty point for each gallon of
                    commercial diesel fuel purchased at One9, Pilot and Flying J locations
                    when you use your Axle Fuel Card. You must present your myRewards loyalty
                    card or the phone number associated with myRewards loyalty account at the
                    time of purchase. DEF not included. Does not apply to in-store purchases.
                    myRewards terms and conditions apply. Void where prohibited or otherwise restricted.
                </p>
                <br />
                <p className="one9-terms__copy">
                    Receive one (1) free shower credit each time you complete a minimum 50-gallon
                    commercial diesel fueling transaction at a One9 location, and present your
                    myRewards loyalty card or the phone number associated with your myRewards
                    loyalty account at the time of fueling. Credit valid for 10 days following
                    qualifying transaction. Offer can be redeemed at participating owned and
                    operated One9 locations or participating U.S. Pilot or Flying J locations.
                    No cash value, rainchecks, or substitutes allowed. Not valid on prior
                    purchases. Offer cannot be sold or otherwise bartered. Other terms,
                    conditions, and restrictions may apply. Offer void where prohibited or otherwise restricted.
                </p>
                <br />
                <p className="one9-terms__copy">
                    PushForPoints™ Program: To participate in the PushForPoints™ Program,
                    Members must activate the PushForPoints™ Program each month in the myRewards
                    Plus app. After activation, members earn 1 point per commercial diesel gallon
                    and increase points with future qualifying fill. Must fuel at least 75 gallons
                    for each “qualifying fill”; limit of 1 qualifying fill per day. Each qualifying
                    fill adds additional 1/2 point per gallon to your myRewards account for all
                    gallons purchased the rest of the month, up to 4 points per gallon. Level
                    points reset to 1 point per gallon on 1st of each month. Must present myRewards
                    card, barcode in app, or phone number associated with myRewards account at
                    time of purchase. Valid for diesel fuel purchases only; not valid on DEF.
                    Valid only at participating U.S. Pilot or Flying J branded, owned and/or
                    operated locations and One9 locations. Not guaranteed at dealer/licensee
                    locations or locations operated under a different brand name. Not valid in
                    Canada. Void where prohibited or otherwise restricted. Purchase required.
                    Invalid on prior purchases. Cannot be sold, bartered, or combined with other
                    offers. No cash value, rain checks, or substitutions allowed. Offer may be
                    modified or terminated at any time. Subject to myRewards terms and conditions.
                    Other terms, conditions, and restrictions may apply. Data rates may apply.
                </p>
                <br />
                <p className="one9-terms__copy">
                    To receive the 10% Off Food and Beverage Pilot Flying J Military discount,
                    you must be authenticated in the myRewards Plus app through ID.me™ and
                    must present myRewards card, barcode in app, or phone number associated
                    with myRewards account at time of purchase. Valid only at participating
                    U.S. Pilot or Flying J branded, owned and/or operated locations and One9
                    locations. Not guaranteed at dealer/licensee locations or locations operated
                    under a different brand name. Discount valid in United States and Canadian
                    locations to United States and Canadian military guests who have verified
                    using ID.me. Military discount valid only on food and beverage purchases only,
                    including coffee, fountain, hot and cold food items, purchases at participating
                    owned and operated QSR restaurants, non-alcoholic cooler beverages, candy, and
                    snacks. Void where prohibited or otherwise restricted. Purchase required.
                    Invalid on prior purchases. Cannot be sold, bartered, or combined with
                    other offers. No cash value, rain checks, or substitutions allowed.
                    Offer may be modified or terminated at any time. Subject to myRewards
                    terms and conditions. Other terms, conditions, and restrictions may
                    apply. Data rates may apply.
                </p>
                <br />
                <p className="one9-terms__copy">
                    Adding a payment card to your virtual wallet is necessary in order to use
                    Mobile Fueling. Valid at participating Pilot, Flying J, and One9 fuel network
                    locations. Subject to availability for other U.S. and Canadian locations.
                </p>
                <br />
                <p className="one9-terms__copy">
                    Comparison based on an average fill size of 100 diesel gallons in other
                    nationally branded travel center rewards programs as of 2022. Store retail
                    value per 1,200 commercial diesel gallons based on twelve 100 gallon fills.
                </p>
                <br />
            </div>
        </DisclaimersStyled>
    );
};

export default Disclaimers;
