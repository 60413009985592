import styled from 'styled-components';

const IndexStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    #what-is-one9 {
        position: relative;
        margin-top: -404px;
        z-index: 8000;

        .card__image {
            background-position: center;
        }

        .button {
            margin-top: 24px;
        }

        @media screen and (min-width: 768px) {
            align-self: flex-end;
            margin-top: -251px;
            height: 641px;

            .card__image {
                max-width: 600px;
            }

            .card__info {
                padding-left: 9%;
            }
        }
    }

    #how-to-pay {
        @media screen and (min-with: 768px) {
            margin-bottom: 44px;
        }
        .card__info {
            .card__body-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    margin: 10px 0 16px 0;
                }
            }
        }
    }

    #how-to-pay {
        margin-bottom: 44px;

        .card__info {
            .card__body-text {
                max-width: 670px;
                margin-top: 10px;
                text-align: left;

                img {
                    width: 253px;
                    height: auto;
                    margin: 0 36px 0 0;
                }
            }
        }
    }

    #introducing-trucker-burger {
        max-width: 1300px;
        margin: 120px 0 100px;

        @media screen and (min-width: 768px) {
            height: 489px;
        }

        .card__image {
            background-position: center;

            @media screen and (min-width: 768px) {
                width: 49.99%;
            }
        }

        .card__info {
            .card__title {
                width: 100%;

                @media screen and (min-width: 768px) {
                    width: 71%;
                }
            }

            @media screen and (min-width: 768px) {
                width: 50%;
            }
        }

        .button {
            margin-top: 32px;
        }
    }

    #apply-for-network {
        @media screen and (min-width: 768px) {
            align-self: flex-start;
            width: 100%;
            height: 550px;
            margin-top: 0;
        }

        .card__info {
            @media screen and (min-width: 768px) {
                display: flex;
                align-items: flex-start;
                height: 100%;

                .card__title {
                    max-width: 450px;
                }

                .card__body-text {
                    max-width: 420px;
                }

                .button {
                    align-self: flex-start;
                    text-align: center;
                }
            }

            @media screen and (min-width: 1024px) {
                padding-left: 10%;
            }

            @media screen and (min-width: 1440px) {
                padding-left: 24%;
            }
        }

        .card__image {
            @media screen and (min-width: 768px) {
                order: 1;
                max-width: 720px;
                height: 550px;
                background-size: cover;
            }
        }

        .button {
            margin-top: 20px;
        }
    }
`;

export default IndexStyled;
