import React from 'react';
import styled from 'styled-components';

import OpenRoad from '../images/open-road.jpg';
import OpenRoadMobile from '../images/open-road--mobile.jpg';

const LocatonMapStyled = styled.div`
    display: flex;
    justify-content: center;
    background: url(${OpenRoadMobile}) no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;

    @media screen and (min-width: 768px) {
        background: url(${OpenRoad}) no-repeat;
        background-position: center;
        background-size: cover;
        justify-content: flex-start;
        padding-left: 10%;
        height: 810px;
        background-color: #3d3935;
        box-sizing: border-box;
    }

    #where-to-fuel {
        margin-top: 534px;

        @media screen and (min-width: 768px) {
            margin-top: 96px;
            width: 600px;

            .card__info {
                padding-left: 100px;
                padding-right: 100px;
                height: initial;
            }
        }
    }
`;

const LocationMap = props => {
    return (
        <LocatonMapStyled className="location-map">
            {props.children}
        </LocatonMapStyled>
    );
};

export default LocationMap;
